.bg-blue-gradient {
  background-image: linear-gradient(90deg, #27a4e1 0, #7ec8ed);
}

.mock-phone {
  transform: translateX(10%);
}

@media (min-width: 768px) {
  .mock-phone {
    transform: none;
  }
}
