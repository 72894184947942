.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-pagination-bullet-active {
  background: #424141;
}

@media (min-width: 280px) and (max-width: 767px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

@media (min-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    color: #424141;
    outline: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: white;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    --swiper-navigation-size: 14px;
    top: 45%;
  }
}

.review-img {
  max-height: 20rem;
}
